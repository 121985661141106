<template>

  <v-img class="bg-white" src="@/assets/img/service-description.jpg" cover></v-img>

  <!--<div class="position-relative" :style="`background: #1A237E; background-size: cover; background-position: 50%;`">
    <span class="mask opacity-2"></span>

    <v-card-text class="text-center text-white font-weight-bold pb-0">
      <p class="text-center text-white text-h4 mb-0">
        Aiwin智勝
        <br />
        自動下注機器人服務說明
      </p>
      <p class="text-left text-white text-body mb-0">
        智勝提供先進的自動下注機器人服務，讓用戶能夠自行
        設定投注策略，並透AI機器人自動執行投注。
        我們專注於提供投注開彩和派彩結果的處理，幫助您高
        效管理投注過程。請注意，所有投注策略和風險由用戶
        自行承擔，本服務不對投注結果負責。特別地，由於開
        彩及派彩的原因，BINGO BINGO賓果賓果遊戲在本網
        站的最後一期下注時間為 23：54。
      </p>
    </v-card-text>
    <v-container fluid>
      <v-card class="card-shadow border-radius-xl">
        <v-card-title class="pt-4 text-h5 text-typo justify-center font-weight-bold">具體服務說明如下</v-card-title>
        <hr class="horizontal mx-2 font-weight-bold" :class="sidebarTheme == 'dark' ? 'light' : 'dark'"
          style="color:#1A237E;background-color:#1A237E" />
        <v-card-text class="text-left">
          <p class="text-left text-typo text-body">
            <b>1. 用戶自定義投注策略</b><br />
            ●策略設定：用戶可以在平台上自行設定投注策略，包括
            投注金額、賠率範圍、投注條件等。系統提供多種自定
            義選項，讓用戶根據個人需求制定合適的投注規則。<br />
            ●策略管理：用戶可隨時調整和優化投注策略，AI機器
            人系統將根據用戶設定的策略進行自動投注。
          </p>
          <p class="text-left text-typo text-body">
            <b>2. 自動化投注執行</b><br />
            ●投注開彩：自動下注機器人根據用戶設定的策略執行投
            注，並在開彩後自動獲取和記錄結果。<br />
            ●派彩結果：系統會自動處理和展示派彩結果，提供詳細
            的投注和派彩數據，讓用戶及時了解投注情況和結果。
          </p>
          <p class="text-left text-typo text-body">
            <b>3. 特別說明：</b><br />
            ●BINGO BINGO賓果賓果最後一期時間：
            由於開彩及派彩的原因，BINGO BINGO賓果賓果遊戲
            在本網站的最後一期時間為 23：54。請用戶在此時間前
            完成投注，以確保您的投注能參與當天最後一期遊戲。
          </p>
          <p class="text-left text-typo text-body">
            <b>4. 實時數據監控與反饋</b><br />
            ●投注監控：提供實時的投注數據監控功能，讓用戶隨時
            了解當前投注狀態。<br />
            ●結果報告：生成投注結果報告，詳細記錄投注和派彩情
            況，幫助用戶進行數據分析和策略調整。
          </p>
          <p class="text-left text-typo text-body">
            <b>5. 易用的界面與操作</b><br />
            ●用戶友好界面：我們提供直觀易用的操作界面，方便用
            戶進行投注策略設定和管理。<br />
            ●設定向導：提供詳細的設定向導和幫助文檔，協助用戶
            快速上手並有效使用系統。
          </p>
          <p class="text-left text-typo text-body">
            <b>6. 技術支持與維護</b><br />
            ●技術支持：我們的客服團隊提供全天候技術支持，解答
            用戶在使用過程中遇到的任何問題，並提供必要幫助。<br />
            ●系統維護：定期進行系統維護和升級，確保自動下注機
            器人穩定運行並適應市場變化。
          </p>
          <p class="text-left text-typo text-body">
            <b>7. 免責聲明</b><br />
            ●風險聲明：Aiwin智勝自動下注機器人僅提供投注開彩
            和派彩結果處理，所有投注策略和風險由用戶自行承擔
            投注結果完全取決於用戶自定義的策略和市場條件。<br />
            ●免責條款：本網站對用戶的投注結果、盈虧或任何相關
            財務損失不承擔責任。用戶需在使用本服務前充分了解
            相關風險，並對自己的投注決策負責。
          </p>
          <p class="text-left text-typo text-body">
            <b>聯繫我們</b><br />
            如需了解更多關於Aiwin智勝自動下注機器人的資訊，或
            希望開始使用我們的服務，請直接聯繫我們的客服團隊。
            我們致力於為您提供高效的自動投注管理工具，並期待幫
            助您優化投注過程。
          </p>
        </v-card-text>

      </v-card>
    </v-container>
  </div>-->
</template>
<script>
export default {
  name: "getting-started",
};
</script>
